body{
    background-color: #1D76BB;
}

h1, h2{
    color:#FFF;
    font-size: 50px;
    font-family: Bahnschrift, serif;
}

p{
    color: #FFF;
}

.App {
    text-align: center;
    margin: 0 15% 0 15%;
}

#ateBallIcon{
    margin:1em;
}

#ateBallIcon:hover{
    cursor: pointer;
}
